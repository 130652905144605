import { useVerifyPartnerAccount } from "hooks";
import { FcGoogle } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { parseDateShort } from "utils";

export function AccountCreationCard() {
  const verifyPartnerState = useVerifyPartnerAccount();

  return (
    <div className="border-primary surface-white flex flex-col gap-5 self-stretch rounded-lg border p-5 shadow-md">
      <span className="text-primary text-sm font-bold">Account Creation</span>

      <div className="flex flex-row gap-2 p-2">
        {verifyPartnerState?.data?.user?.isSSO ? (
          <FcGoogle className="size-5" />
        ) : (
          <MdEmail className="size-5" />
        )}
        <span className="text-primary text-sm font-normal">
          Your account was created on{" "}
          <span className="font-medium">
            {parseDateShort(verifyPartnerState?.data?.user?.createdAt)}
          </span>{" "}
          via{" "}
          <span className="font-medium">
            {verifyPartnerState?.data?.user?.isSSO
              ? "Google SSO"
              : "Email & Password"}
          </span>
          .
        </span>
      </div>
    </div>
  );
}
