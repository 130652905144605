import { LandingFooter, Navbar } from "layouts";
import { GiUnplugged } from "react-icons/gi";

export function MaintenancePage() {
  return (
    <>
      <div className="surface-secondary flex h-screen flex-col self-stretch bg-fig-bg-new bg-cover">
        <Navbar variant="mfaCheck" />

        <div className="font-sohne flex h-full w-full flex-col items-center justify-center p-8">
          <div className="surface-white border-primary flex flex-col gap-4 rounded-lg border p-8 laptop:flex-row laptop:gap-[72px] laptop:p-[48px] desktop:-mt-[55px]">
            <div className="flex w-full flex-col items-center gap-8">
              <div className="flex h-24 w-24 items-center justify-center rounded-full bg-gray-100">
                <GiUnplugged className="text-primary size-15" />
              </div>
              <span className="text-center text-4xl font-semibold text-black">
                Maintenance mode
              </span>

              <span className="flex text-center text-sm font-normal text-gray-600 tablet:w-[75%]">
                We are working to improve your experience using our app. Please
                check back later.
              </span>
            </div>
          </div>
        </div>
      </div>

      <LandingFooter />
    </>
  );
}
