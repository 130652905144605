import { useEffect, useState } from "react";

import { verifyPartnerAccount } from "api";
import {
  offerDiscount1,
  offerDiscount2,
  offerDiscount3,
  offerOption1,
  offerOption2,
  offerOption3,
  offerPartnerImg,
} from "assets";
import {
  Button,
  HuntressOfferAlert,
  PageSpinner,
  PricingOfferCard,
  SelectOfferModal,
  SpecialOfferAlert,
} from "components";
import { useFetchSuppliersWidgetData } from "hooks";
import { HiChatAlt2, HiChevronRight, HiX } from "react-icons/hi";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { capitalize } from "services";
import { openZEWidget } from "utils";
import { ROUTES } from "variables";

const VALID_TYPES = ["huntress"];

export function OfferPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [specialOffer, setSpecialOffer] = useState();

  const { type } = useParams();

  const fetchSuppliersState = useFetchSuppliersWidgetData();
  const navigate = useNavigate();

  const handleSelectOffer = (index) => {
    setIsSelectModalOpen(true);
    setSelectedOffer(index);
  };

  useEffect(() => {
    if (searchParams.get("cancel") === "true") {
      sessionStorage.removeItem("STRIPE_ACTION");
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      if (type) {
        if (!VALID_TYPES.includes(type)) {
          navigate(ROUTES.DASHBOARD);
          return;
        }

        let result = await verifyPartnerAccount({ specialOfferCode: type });
        if (result?.specialOffer) {
          setSpecialOffer(result?.specialOffer);
          setIsLoading(false);
        } else {
          navigate(ROUTES.DASHBOARD);
        }
      } else if (!!fetchSuppliersState?.data) {
        if (
          !fetchSuppliersState?.data?.partner?.specialOffer.find(
            (offer) => offer.name === "PARTNER_SPECIAL_CERT_BUNDLE",
          )
        ) {
          navigate(ROUTES.DASHBOARD);
        }
        setIsLoading(false);
      }
    })();
  }, [type, fetchSuppliersState?.data]);

  useEffect(() => {
    if (specialOffer) console.log(specialOffer);
  }, [specialOffer]);

  return isLoading ? (
    <PageSpinner />
  ) : (
    <>
      <div className="flex flex-col gap-[42px]">
        <SpecialOfferAlert
          offer={fetchSuppliersState?.data?.partner?.specialOffer.find(
            (offer) => offer.name === "PARTNER_SPECIAL_CERT_BUNDLE",
          )}
          button={
            <>
              <Button
                leftIcon={<HiX className="size-4 text-white" />}
                label={"Close"}
                labelStyles={`whitespace-nowrap text-base`}
                variant={"purple"}
                onClick={() => navigate(ROUTES.DASHBOARD)}
              />
            </>
          }
        />

        {type === "huntress" && (
          <HuntressOfferAlert
            offer={fetchSuppliersState?.data?.partner?.specialOffer.find(
              (offer) => offer.name === "huntress",
            )}
            button={
              <>
                <Button
                  leftIcon={<HiX className="size-4 text-white" />}
                  label={"Close"}
                  labelStyles={`whitespace-nowrap text-base`}
                  variant={"sky"}
                  onClick={() => navigate(ROUTES.DASHBOARD)}
                />
              </>
            }
          />
        )}

        {specialOffer?.banner && (
          <img
            src={specialOffer?.banner}
            alt={`${capitalize(type)} Banner`}
            className="aspect-auto w-65 py-7.5"
          />
        )}

        <div className="text-gray flex w-full flex-col justify-start gap-8 text-sm tablet:justify-between laptop:flex-row">
          <div className="flex w-full flex-col gap-8 laptop:w-1/2">
            <h1 className="text-primary text-[34px] font-semibold leading-tight">
              Exclusive offer for our new Partners!
            </h1>

            {OFFER_DESCRIPTION[!!type ? type : "default"]}
          </div>

          <div className="w-full laptop:w-[50%]">
            <img
              src={!!type ? specialOffer?.hero : offerPartnerImg}
              className="pointer-events-none flex h-full w-full resize-none justify-center rounded-lg bg-gray-50 object-cover shadow-lg"
              alt="Offer for new Partners"
            />
          </div>
        </div>

        <div className="flex w-full flex-col gap-8 laptop:flex-row">
          {OFFER_OPTIONS[!!type ? type : "default"].map((option, index) => (
            <PricingOfferCard
              key={index}
              index={index}
              {...option}
              handleSelectOffer={handleSelectOffer}
            />
          ))}
        </div>

        <div className="flex flex-col gap-4">
          <h3 className="text-primary text-base font-bold">
            Terms & Conditions
          </h3>

          <div className="flex flex-col gap-1">
            {TERMS_AND_CONDITIONS[!!type ? type : "default"].map(
              (term, index) => (
                <TermItem key={index}>{term}</TermItem>
              ),
            )}
          </div>
        </div>

        <Button
          customClasses={`w-full laptop:w-fit`}
          leftIcon={<HiChatAlt2 className="size-4" />}
          label={"Contact Us"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={openZEWidget}
        />
      </div>

      <SelectOfferModal
        isOpen={isSelectModalOpen}
        option={OFFER_OPTIONS[!!type ? type : "default"][selectedOffer]}
        handleCloseModal={() => setIsSelectModalOpen(false)}
      />
    </>
  );
}

const TermItem = ({ children }) => (
  <div className="flex flex-row gap-2">
    <HiChevronRight className="text-link my-px size-4 flex-shrink-0" />
    <div className="text-gray text-xs">{children}</div>
  </div>
);

const OFFER_DESCRIPTION = {
  huntress: (
    <>
      <h2 className="text-primary text-lg font-semibold">
        Thanks to Huntress, we're excited to present an exclusive, fully-funded
        subscription bundle tailored specifically for your MSP needs to help you
        get started!
      </h2>
      <p>
        Please select one (1) bundle from the three (3) options below that best
        suit your client base.
      </p>
      <p>
        <span className="font-semibold"> Option 1 Variety</span> - best fit for
        a broad client base of micro-operators through to mid sized businesses.
        <br />
        <span className="font-semibold"> Option 2 Standard</span> - best fit for
        clients that range from midsize to large businesses.
        <br />
        <span className="font-semibold"> Option 3 Premiere</span> - best fit for
        predominantly larger businesses.
      </p>

      <p>
        Once you have made your selection, the subscription credits will be
        added to your account ready for you to distribute or use with your
        clients.
      </p>
      <p>Welcome to the CyberCert Partner Program, supported by Huntress!</p>
    </>
  ),
  default: (
    <>
      <h2 className="text-primary text-lg font-semibold">
        "We're excited to present an exclusive, limited-time offer tailored
        specifically for our new partners!"
      </h2>
      <p className="font-semibold">
        To kickstart your journey with us, we've crafted three high-value
        subscription bundles at an extraordinary discount!
      </p>
      <p>
        These bundles are designed not only to enhance your business
        capabilities but also to serve as a powerful way to boost your client
        engagements. Whether you choose to resell these subscriptions or use
        them to captivate and motivate your clients to elevate their cyber
        security, the flexibility is yours.
      </p>
      <p>
        Feel free to mix and match, purchase multiple bundles, or select just
        one to suit your needs.{" "}
        <span className="font-semibold">
          But hurry - this special offer is only available for 14 days following
          your onboarding.
        </span>
      </p>
    </>
  ),
};

const OFFER_OPTIONS = {
  huntress: [
    {
      title: "Option 1",
      size: "Variety",
      image: offerOption1,
      discountImage: offerDiscount1,
      details: [
        { label: "BRONZE subscriptions", value: "x3" },
        { label: "SILVER subscriptions", value: "x2" },
        { label: "GOLD subscriptions", value: "x1" },
      ],
      price: "$995",
      name: "variety",
      label: "Select",
      zero: true,
    },
    {
      title: "Option 2",
      size: "Standard",
      image: offerOption2,
      discountImage: offerDiscount2,
      details: [
        { label: "SILVER subscriptions", value: "x2" },
        { label: "GOLD subscriptions", value: "x2" },
      ],
      price: "$1,595",
      name: "standard",
      label: "Select",
      zero: true,
    },
    {
      title: "Option 3",
      size: "Premiere",
      image: offerOption3,
      discountImage: offerDiscount3,
      details: [{ label: "GOLD subscriptions", value: "x3" }],
      price: "$1,995",
      name: "premiere",
      label: "Select",
      zero: true,
    },
  ],
  default: [
    {
      title: "Option 1",
      size: "Micro-Small",
      image: offerOption1,
      discountImage: offerDiscount1,
      details: [
        { label: "BRONZE subscriptions", value: "x10" },
        { label: "SILVER subscriptions", value: "x5" },
        { label: "GOLD subscriptions", value: "x3" },
      ],
      price: "$995",
      name: "CAP Partner Offer: Micro-Small",
    },
    {
      title: "Option 2",
      size: "Small-Medium",
      image: offerOption2,
      discountImage: offerDiscount2,
      details: [
        { label: "SILVER subscriptions", value: "x20" },
        { label: "GOLD subscriptions", value: "x5" },
      ],
      price: "$1,595",
      name: "CAP Partner Offer: Small Medium",
    },
    {
      title: "Option 3",
      size: "Medium +",
      image: offerOption3,
      discountImage: offerDiscount3,
      details: [{ label: "GOLD subscriptions", value: "x20" }],
      price: "$1,995",
      name: "CAP Partner Offer: Medium+",
    },
  ],
};

const TERMS_AND_CONDITIONS = {
  huntress: [
    "Subscription credits do not expire and are non-refundable.",
    <>
      Subscription credits cannot be resold for more than the RRP on the{" "}
      <Link
        href="https://www.cybercert.ai/en-au"
        target="_blank"
        referrerPolicy="noreferrer"
        className="text-link cursor-pointer font-medium hover:text-blue-700 hover:underline"
      >
        CyberCert website.
      </Link>
    </>,
    <>
      See our{" "}
      <Link
        href="https://www.cybercert.ai/en-au/terms"
        target="_blank"
        referrerPolicy="noreferrer"
        className="text-link cursor-pointer font-medium hover:text-blue-700 hover:underline"
      >
        Terms of Use
      </Link>{" "}
      for more information relating to certification.
    </>,
  ],
  default: [
    "Offer is valid for 14 days from completion of Partner Dashboard onboarding.",
    <>
      <button
        onClick={openZEWidget}
        className="text-link cursor-pointer font-medium hover:text-blue-700 hover:underline"
      >
        Contact us
      </button>{" "}
      if you need to purchase via invoice / bank transfer.
    </>,
    "Subscription credits will be added to your Dashboard when your payment has been finalised.",
    "Subscription credits do not expire and are non-refundable.",
    <>
      Subscription credits cannot be resold for more than the RRP on the{" "}
      <Link
        href="https://www.cybercert.ai/en-au"
        target="_blank"
        referrerPolicy="noreferrer"
        className="text-link cursor-pointer font-medium hover:text-blue-700 hover:underline"
      >
        CyberCert website.
      </Link>
    </>,
    "All prices are in Australian dollars and exclude GST.",
    <>
      See our{" "}
      <Link
        href="https://www.cybercert.ai/en-au/terms"
        target="_blank"
        referrerPolicy="noreferrer"
        className="text-link cursor-pointer font-medium hover:text-blue-700 hover:underline"
      >
        Terms of Use
      </Link>{" "}
      for more information relating to certification.
    </>,
  ],
};
