const { API } = require("aws-amplify");

export const StatusPartnerOrgsData = async ({ dashboard }) => {
  let result;

  try {
    let myInit = {
      queryStringParameters: {
        dashboard,
        op: "status",
      },
    };

    result = await API.get("partner", "/organisationOp", myInit);
    // console.log(result);
  } catch (e) {
    console.error(e);
  }

  return result;
};
