import { useEffect, useMemo, useState } from "react";

import { openPartnerBulkCheckoutSession } from "api";
import { BulkPurchaseTable } from "components";
import { useDashboard } from "contexts";
import { CgSpinner } from "react-icons/cg";
import { HiCheck } from "react-icons/hi";
import { HiNoSymbol } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { deepClone, formatPrice } from "utils";
import { CERT_LVL_BADGES, CERT_LVLS, CERT_LVLS_PRICES } from "variables";

const INIT_STATE = {
  qtyBronze: 0,
  qtySilver: 0,
  qtyGold: 0,
  qtyPlatinum: 0,
  qtyDiamond: 0,
};

export function BulkPurchaseCard({
  subscriptionDiscount = 0,
  volumeDiscount = 0,
  volumeAmount = 0,
}) {
  const [bulkPurchase, setBulkPurchase] = useState(deepClone(INIT_STATE));
  const [isLoading, setIsLoading] = useState(false);

  const { dashboard } = useDashboard();
  const { hash } = useLocation();

  const handleInput = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    if (value === "" || /^[0-9]*$/.test(value)) {
      setBulkPurchase((prev) => ({
        ...prev,
        [name]: value !== "" ? parseInt(value) : 0,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    await openPartnerBulkCheckoutSession({
      products: {
        bronze: bulkPurchase.qtyBronze,
        silver: bulkPurchase.qtySilver,
        gold: bulkPurchase.qtyGold,
      },
      dashboard: dashboard.route,
    });
    setIsLoading(false);
  };

  const totalQty = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return acc + bulkPurchase[`qty${CERT_LVLS[level]}`];
    }, 0);
  }, [bulkPurchase]);

  const isEnough = useMemo(() => {
    return totalQty >= volumeAmount && volumeAmount > 0 && volumeDiscount > 0;
  }, [totalQty, volumeAmount]);

  const subtotal = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return (
        acc + CERT_LVLS_PRICES[level] * bulkPurchase[`qty${CERT_LVLS[level]}`]
      );
    }, 0);
  }, [bulkPurchase]);

  useEffect(() => {
    // Check if there's a hash in the URL
    if (hash === "#bulk-purchase") {
      const elem = document.getElementById("bulk-purchase");
      if (elem) {
        window.scrollTo({
          top: elem.getBoundingClientRect().top + window.scrollY - 128,
          behavior: "smooth",
        });
      }
    }
  }, [hash]);

  return (
    <form
      id="bulk-purchase"
      className="border-primary surface-primary flex gap-8 self-stretch rounded-lg border p-8 shadow-lg"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-1 flex-col gap-5">
        <div className="flex flex-col gap-2">
          <span className="text-secondary text-base font-bold">
            Bulk Purchase
          </span>
          {volumeDiscount > 0 && (
            <span className="text-gray text-sm">
              Get {volumeDiscount}% discount off total price for minimum
              purchase of {volumeAmount} subscriptions.
            </span>
          )}
        </div>

        <BulkPurchaseTable
          bulkPurchase={bulkPurchase}
          isLoading={isLoading}
          handleInput={handleInput}
        />

        {volumeDiscount > 0 && (
          <div className="border-primary flex justify-between rounded-lg border p-5">
            <div className="flex flex-col gap-1">
              <span className="font-semibold">
                Bulk Subscription Discount {volumeDiscount}%
              </span>
              <span className="text-sm font-medium text-gray-500">
                Minimum volume purchase of {volumeAmount} subscriptions is
                required
              </span>
            </div>

            <div
              className={`flex items-center gap-2 ${isEnough ? "text-green-500" : "text-red-500"}`}
            >
              <span className="text-lg font-bold">{totalQty}</span>
              {isEnough ? (
                <HiCheck className="size-4" />
              ) : (
                <HiNoSymbol className="size-4" />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="surface-db-stepper border-primary flex flex-1 flex-col gap-5 rounded-lg border p-5">
        <span className="font-bold">Order Summary</span>

        {Object.keys(CERT_LVLS).map((level) => (
          <div key={level} className="flex justify-between">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-4">
                <img
                  className="h-7"
                  src={CERT_LVL_BADGES[level]}
                  alt={`${CERT_LVLS[level]} Badge`}
                />
                <span className="font-semibold uppercase">
                  {CERT_LVLS[level]}
                </span>
              </div>
              <span className="text-sm font-medium text-gray-500">
                {formatPrice(CERT_LVLS_PRICES[level])} / Certificate
              </span>
            </div>

            <span className="text-gray font-bold">
              {formatPrice(
                CERT_LVLS_PRICES[level] *
                  bulkPurchase[`qty${CERT_LVLS[level]}`],
              )}
            </span>
          </div>
        ))}

        <hr className="border-primary border-b border-dashed" />

        <div className="flex flex-col gap-2 text-sm font-medium">
          <div className="flex justify-between">
            <span className="text-gray-500">Tax</span>
            <span className="text-gray">
              Calculated at checkout, if applicable
            </span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-500">Subtotal</span>
            <span className="text-gray">{formatPrice(subtotal)}</span>
          </div>

          {(isEnough || !!subscriptionDiscount) && (
            <div className="flex justify-between">
              <span className="text-gray-500">
                {isEnough
                  ? `${volumeDiscount}% Discount`
                  : `${subscriptionDiscount}% Discount`}
              </span>
              <span className="text-gray line-through">
                {formatPrice(
                  ((isEnough ? volumeDiscount : subscriptionDiscount) / 100) *
                    subtotal,
                )}
              </span>
            </div>
          )}

          <div className="text-primary flex justify-between text-lg font-bold">
            <span>Total</span>
            <span>
              {formatPrice(
                isEnough
                  ? subtotal * (1 - volumeDiscount / 100)
                  : subtotal * (1 - subscriptionDiscount / 100),
              )}
            </span>
          </div>
        </div>

        <button
          className="btn-brand w-fit gap-2 self-end px-3 py-2"
          disabled={totalQty <= 0 || isLoading}
          type="submit"
        >
          {isLoading && <CgSpinner className="size-5 animate-spin" />}
          <span className="text-sm font-bold">Checkout</span>
        </button>
      </div>
    </form>
  );
}
