const { API } = require("aws-amplify");

export const ConfirmPartnerOrgsData = async ({ dashboard, data }) => {
  let result;

  try {
    let myInit = {
      body: {
        dashboard,
        data,
        op: "confirm",
      },
    };

    result = await API.post("partner", "/organisationOp", myInit);
    // console.log(result);
  } catch (e) {
    console.error(e);
  }

  return result;
};
