import { useEffect, useState } from "react";

import { Button } from "components";
import { PartnerBenefitCard } from "components/cards/partner-benefit-card";
import {
  HiArrowLeft,
  HiArrowRight,
  HiCheck,
  HiExternalLink,
  HiOfficeBuilding,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { parseDateShort } from "utils";

export function DoneOnboarding({
  organisation,
  partner,
  mfaDate,
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const navigate = useNavigate();
  const partnerTypes = [
    "Consultant",
    "Franchise",
    "Membership",
    "MSP",
    "Supply Chain",
    "Vendor",
  ];
  const partnerProps = [
    "isTypeConsultant",
    "isTypeFranchise",
    "isTypeMembership",
    "isTypeMSP",
    "isTypeSupplyChain",
    "isTypeVendor",
  ];
  const [benefits, setBenefits] = useState([]);
  const [partnerTypeData, setPartnerTypeData] = useState();
  const [organisationData, setOrganisationData] = useState();
  const [profileData, setProfileData] = useState();

  const confirmOnboard = () => {
    handleNextStep();
  };

  useEffect(() => {
    setBenefits([
      {
        label: "Subscription Discount",
        value: `${partner?.subscriptionDiscount}%` || "NA",
      },
      {
        label: "Code Expiry",
        value: partner?.codeExpiry ? parseDateShort(partner.codeExpiry) : "NA",
      },
      {
        label: "Volume Subscription Discount",
        value: !!partner?.volumeDiscount ? `${partner?.volumeDiscount}%` : "NA",
      },
      {
        label: "Minimum Volume Purchase (Subscriptions)",
        value: partner?.minimumVolume || "NA",
      },
    ]);
    setPartnerTypeData([
      { label: "Partner Type", value: partner?.partnerType },
      { label: "Program Type", value: partner?.programType },
    ]);
    setOrganisationData([
      { label: "ABN", value: partner?.ABN },
      {
        label: "Entity Type",
        value: organisation?.industry,
      },
      { label: "Entity Name", value: organisation?.name },
      { label: "Preferred Organisation Name", value: partner?.name },
      { label: "Website", value: partner?.website },
    ]);
    setProfileData([
      { label: "Name", value: `${partner?.firstName} ${partner?.lastName}` },
      { label: "Work Email Address", value: partner?.email },
      { label: "Mobile", value: partner?.phone },
      { label: "Role", value: partner?.role },
    ]);
  }, [partner, organisation]);

  return (
    <>
      <div className="flex flex-col self-stretch">
        <div className="text-primary text-lg font-bold">
          Congratulations! You have finished onboarding.
        </div>

        <div className="text-gray text-sm">
          These details can be updated later in settings.
        </div>
      </div>

      <div className="flex flex-col gap-8 self-stretch laptop:flex-row laptop:gap-16">
        <div className="flex w-full flex-col gap-8 self-stretch">
          <div className="flex flex-col gap-8 self-stretch">
            <div className="flex items-center gap-3 self-stretch laptop:order-1">
              {!!partner?.logoUrl ? (
                <img
                  src={partner.logoUrl}
                  alt="Large Partner Logo"
                  className="h-7.5"
                />
              ) : (
                <>
                  <div className="border-surface-primary m-0 flex aspect-square w-9 items-center justify-center overflow-hidden rounded-full border object-cover p-0">
                    {!!partner?.faviconUrl ? (
                      <img
                        src={partner.faviconUrl}
                        alt="Partner Favicon"
                        className="aspect-square w-full scale-110 overflow-hidden object-cover"
                      />
                    ) : (
                      <HiOfficeBuilding className="text-gray size-7.5" />
                    )}
                  </div>
                </>
              )}

              <div className="flex flex-col self-stretch">
                <div className="text-secondary text-base font-bold">
                  {partner?.name || organisation?.name}
                </div>
                <div className="text-gray text-xs">
                  <span className="font-bold">ABN</span> {partner?.ABN}
                </div>
              </div>
            </div>

            <PartnerBenefitCard partnerBenefits={benefits} />

            <div className="flex flex-col gap-5 self-stretch laptop:order-2">
              <div className="text-primary text-sm font-bold">Partner Type</div>

              <div className="flex flex-col self-stretch">
                <div className="border-primary flex items-start gap-2 self-stretch border-b py-2">
                  <div className="text-gray w-full text-sm">Partner Type</div>

                  <div className="text-primary w-full text-sm font-medium">
                    Cyber Assurance Program (CAP)
                  </div>
                </div>

                <div className="border-primary flex items-start gap-2 self-stretch border-b py-2">
                  <div className="text-gray w-full text-sm">Program Type</div>

                  <div className="flex w-full flex-col gap-2.5 text-sm font-medium">
                    {partnerTypes?.map((item, index) =>
                      !!partner && partner[partnerProps[index]] === true ? (
                        <div
                          key={index}
                          className="btn-green flex items-center gap-2.5 text-green-500"
                        >
                          {item}
                          <HiCheck className="size-5" />
                        </div>
                      ) : (
                        <div key={index} className="hidden">
                          {item}
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col gap-8 self-stretch">
          <div className="flex flex-col gap-5 self-stretch">
            <div className="text-primary text-sm font-bold">
              Your Organisation
            </div>

            <div className="flex flex-col self-stretch">
              {organisationData?.map((item, index) => (
                <div
                  key={index}
                  className="border-primary flex items-start gap-2 self-stretch border-b py-2"
                >
                  <div className="text-gray w-full text-sm">{item.label}</div>

                  {item.label === "Website" ? (
                    <div className="flex w-full items-center gap-1">
                      <a
                        href={
                          item.value?.startsWith("http://") ||
                          item.value?.startsWith("https://")
                            ? item.value
                            : `http://${item.value}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-link text-sm outline-none hover:opacity-80 focus:underline"
                      >
                        {item.value}
                      </a>
                      <HiExternalLink className="text-icons h-4.5 w-4.5" />
                    </div>
                  ) : (
                    <div className="text-primary w-full text-sm font-medium">
                      {item.value}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-5 self-stretch">
            <div className="text-primary text-sm font-bold">User Details</div>

            <div className="flex flex-col self-stretch">
              {profileData?.map((item, index) => (
                <div
                  key={index}
                  className="border-primary flex items-start gap-2 self-stretch border-b py-2"
                >
                  <div className="text-gray w-full text-sm">{item.label}</div>

                  <div className="text-primary w-full text-sm font-medium">
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-5 self-stretch">
            <div className="text-primary text-sm font-bold">MFA Setup</div>

            <div className="flex flex-col self-stretch">
              <div className="border-primary flex items-start gap-2 self-stretch border-b py-2">
                <div className="text-gray w-full text-sm">
                  Authenticator App
                </div>

                <div className="text-primary w-full text-sm font-medium">
                  Added on {mfaDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full gap-3 self-stretch laptop:w-[556px]">
        <Button
          customClasses={`w-full laptop:w-fit`}
          leftIcon={<HiArrowLeft className="size-4" />}
          label={"Back"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handlePrevStep}
        />

        <Button
          customClasses={"w-full laptop:w-fit"}
          leftIcon={<HiArrowRight className="size-4" />}
          label={"Go to Dashboard"}
          labelStyles={"text-sm font-medium"}
          variant={"white"}
          onClick={confirmOnboard}
        />
      </div>
    </>
  );
}
