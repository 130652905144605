const { API } = require("aws-amplify");

export const fetchGAPAssessment = async ({ id, token }) => {
  const myInit = {
    queryStringParameters: { id, token },
  };
  const result = await API.get("partner", "/gap/fetch", myInit);
  // console.log(result);
  return result;
};
