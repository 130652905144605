const { API } = require("aws-amplify");

export const verifyGAPAssessmentToken = async ({
  token,
  email,
  phone,
  name,
  preview,
}) => {
  const myInit = {
    body: { token, email, phone, name, preview },
  };
  const result = await API.post("GAP", "/assessment/verify", myInit);
  // console.log(result);
  return result;
};
