import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerDashboardDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.partnerDashboard ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks?.partnerDashboard} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <>
          <Spinner theme={SpinnerTheme} color="darkGray" />
        </>
      )}

      <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
        <p className="font-medium text-primary-dark">Partner Dashboard</p>
        <p>
          In the partner dashboard we can initially see several widgets. "Our
          clients" gives a high level summary of your client's current CyberCert
          certification status. "Certification status" also gives more detail,
          organizing by newly certified and expiring certificates for the
          current quarter. Any available "certification subscription credits"
          for your account will be shown in this widget here.
        </p>
      </div>
    </>
  );
}
