const { API } = require("aws-amplify");

export const generateGAPAssessment = async ({
  maximumLevel,
  dashboard,
  assessmentId,
}) => {
  const myInit = {
    body: { maximumLevel, dashboard, assessmentId },
  };
  const result = await API.post("partner", "/gap/generate", myInit);
  // console.log(result);
  return result;
};
