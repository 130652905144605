const { API } = require("aws-amplify");

export const setupPartnerAccount = async ({
  isOfferSetup,
  isTypeCAP,
  isTypeReferrer,
  isTypeSupplyChain,
  isTypeMembership,
  isTypeConsultant,
  isTypeMSP,
  name,
  website,
  firstName,
  lastName,
  phone,
  email,
  role,
  logo,
  favicon,
  prev,
}) => {
  const myInit = {
    body: {
      prev,
      data: {
        isOfferSetup,
        isTypeCAP,
        isTypeReferrer,
        isTypeSupplyChain,
        isTypeMembership,
        isTypeConsultant,
        isTypeMSP,
        name,
        website,
        firstName,
        lastName,
        phone,
        email,
        role,
        logo,
        favicon,
      },
    },
  };
  const result = await API.post("partner", "/setup", myInit);
  // console.log(result);
  return result;
};
