import { useState } from "react";

import { Button } from "components";
import { format } from "date-fns";
import { Modal, Tooltip } from "flowbite-react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";

const BACKGROUNDS = {
  Bronze: "bg-[#DDC2AF]",
  Silver: "bg-[#B4A9A9]",
  Gold: "bg-[#D8B821]",
  Platinum: "bg-[#D1D1D1]",
  Diamond: "bg-[#D0B5D0]",
  Ineligible: "bg-gray-600",
};

const BASE_COLUMN_HEIGHT = 96;

export function SubscriptionsInsightsWidget({ data }) {
  const [option, setOption] = useState("required");
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const getLargestValue = () => {
    return data?.subscriptions.reduce((result, sub) => {
      if (sub?.[option] > result) {
        result = sub?.[option];
      }
    }, 0);
  };

  const getHeight = (value) => {
    return (value / getLargestValue() || 0.2) * BASE_COLUMN_HEIGHT;
  };

  const handleOptionChange = (option) => {
    setOption(option);
  };

  const handleVideo = () => {
    setIsVideoOpen(false);
  };

  const formattedDate = format(new Date(), "EEE dd MMM");

  return (
    <>
      <div className="border-secondary surface-white flex flex-grow flex-col justify-between gap-5 self-stretch rounded-lg border p-5">
        <div className="flex items-center justify-between gap-5 self-stretch">
          <div className="flex items-center gap-3 self-stretch">
            <span className="text-secondary text-base font-bold">
              {option === "required" ? (
                <>Required Subscriptions</>
              ) : (
                <>Issued Subscriptions</>
              )}
            </span>

            <span className="text-gray text-xs font-medium">
              {formattedDate}
            </span>
          </div>

          <div className="flex items-center gap-3 self-stretch">
            <div className="flex items-center gap-2 self-stretch">
              <div className="flex items-center gap-1 self-stretch">
                <Button
                  customClasses="disabled:disabled:opacity-100"
                  disabled={option === "required"}
                  label="Required"
                  variant={option === "required" ? "blue" : "white"}
                  onClick={() => handleOptionChange("required")}
                />

                <Button
                  customClasses="disabled:disabled:opacity-100"
                  disabled={option === "issued"}
                  label="Issued"
                  variant={option === "issued" ? "blue" : "white"}
                  onClick={() => handleOptionChange("issued")}
                />
              </div>

              <div className="flex items-center gap-1">
                {/* <Tooltip
                  animation="duration-150"
                  content="Video Tutorial"
                  placement="bottom"
                  trigger="hover"
                >
                  <HiMiniPlayCircle
                    className="btn-text-green size-5 cursor-pointer"
                    onClick={handleVideo}
                  />
                </Tooltip> */}
                <Tooltip
                  animation="duration-150"
                  content={
                    <div className="flex flex-col gap-2">
                      <span>
                        {option === "required"
                          ? "Required Subscriptions"
                          : "Issued Subscriptions"}
                      </span>
                      <span className="font-normal text-gray-300">
                        The subscriptions widget lets you track the outcomes of
                        gap assessments.
                        <br />
                        <br />
                        Required - GAP assessment has recommended these
                        organisations a SMB1001 certification
                        <br />
                        <br />
                        Issued - SMB1001 certifications that have been issued to
                        organisations.
                      </span>
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
                </Tooltip>
              </div>
            </div>

            {/* <Tooltip content="" placement="bottom"> */}
            {/* <HiQuestionMarkCircle className="text-icons size-4" /> */}
            {/* </Tooltip> */}
          </div>
        </div>

        <div className="flex flex-col gap-5 self-stretch tablet:flex-row">
          <div className="flex flex-1 flex-col justify-between gap-5 self-stretch">
            <div className="flex flex-col gap-2">
              <span className="text-xs">
                {option === "required" ? (
                  <>Assessment recommendations</>
                ) : (
                  <>Distributed subscriptions</>
                )}
              </span>
              <span className="text-3xl font-bold leading-none">
                {data?.subscriptions?.reduce(
                  (sum, obj) => sum + (obj?.[option] || 0),
                  0,
                )}
              </span>
            </div>

            <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
              {data?.subscriptions?.map((sub, index) => (
                <SubscriptionLabel
                  key={index}
                  name={sub?.name}
                  count={sub?.[option]}
                />
              ))}
            </div>
          </div>

          <div
            className="border-secondary flex flex-1 flex-col items-center justify-between gap-6 self-stretch border-t px-4 pt-5 
                    tablet:gap-0 tablet:border-l tablet:border-t-0 tablet:pt-0"
          >
            <span className="text-gray text-xs font-medium">Total</span>

            <div className="flex items-end justify-center gap-2">
              {data?.subscriptions?.map((sub, index) => (
                <SubscriptionColumn
                  key={index}
                  name={sub?.name}
                  count={sub?.[option]}
                  getHeight={getHeight}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={{ header: { popup: "border-b-0" } }}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            <span className="text-secondary">
              {option === "required"
                ? "Required Subscriptions"
                : "Issued Subscriptions"}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/widget_1.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>
    </>
  );
}

function SubscriptionLabel({ name, count }) {
  if (count === undefined) return;
  return (
    <div className="flex items-center gap-1 self-stretch">
      <div className={`rounded-px h-2.5 w-2.5 ${BACKGROUNDS[name]}`} />
      <span className="text-xs font-medium">
        {name} ({count})
      </span>
    </div>
  );
}

function SubscriptionColumn({ name, count, getHeight = () => {} }) {
  if (count === undefined) return;
  return (
    <div className="flex h-24 items-end justify-center gap-2 self-stretch text-xs font-bold text-white">
      <div
        style={{ width: 32, height: getHeight(96, count) }}
        className={`flex flex-col items-center justify-end rounded-t-sm ${BACKGROUNDS[name]}`}
      >
        {count}
      </div>
    </div>
  );
}
