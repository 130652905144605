export function PartnerKnowledgeBaseDocs({ title }) {   
    return (
      <>
        <h2 className="text-2xl font-semibold">{title}</h2>
        <video className="w-full border border-gray-300" controls>
          <source src="your-video-file.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
          <p className="font-medium text-primary-dark">Knowledge Base</p>
          <p>
            On the subscriptions page there are two methods to distribute subscriptions to your clients: Invite links or the Discounted Offer link.
            If your account has available credits, you can simply copy the link from the certification subscriptions credit widget and provide this link to your clients. When businesses use one of these links to sign up and register their CyberCert account, they will receive the chosen certification and can start the certification process immediately.
            On the left widget is the discounted partner offer link that gives any organisation a 35% discount at checkout to purchase a certification themselves. Simply copy this link and provide it to your clients. When they complete the purchase as shown and register their CyberCert account, they can start their certification immediately.
          </p>
        </div>
      </>
    );
}
  