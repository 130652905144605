const { API } = require("aws-amplify");

export const sendPartnerNewAdminInvitation = async ({
  userDetails, // [{ userFullName, userFirstName, userLastName, userEmail, userPhone, userRole }]
}) => {
  let result;
  try {
    const myInit = {
      body: {
        userDetails,
      },
    };
    result = await API.post("partner", "/user-invite/invite", myInit);
  } catch (error) {
    console.error(error);
  }
  return result;
};
