const { API } = require("aws-amplify");

export const updateUserMFA = async ({ preferredMFA }) => {
  const myInit = {
    body: {
      preferredMFA,
    },
  };
  const result = await API.post("user", "/mfa/update", myInit);
  // console.log(result);
  return result;
};
