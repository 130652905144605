import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerPlatformOverviewDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.platformOverview ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks?.platformOverview} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <>
          <Spinner theme={SpinnerTheme} color="darkGray" />
        </>
      )}

      <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
        <p className="font-medium text-primary-dark">
          Partner Platform Overview
        </p>
        <p>
          Once logged in, you have access to two dashboards. The certification
          dashboard, which lets you view and manage your organizations Cyber
          Cert certifications. This is also what your clients will have access
          to when they login. Your Partner dashboard is also listed here, which
          lets you view and manage your Cyber Assurance Program.
        </p>
      </div>
    </>
  );
}
