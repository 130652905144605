const { API } = require("aws-amplify");

export const EditPartnerOrgsData = async ({ dashboard, data }) => {
  let result;

  try {
    // data = { newOrg: { name, ABN, email, mobile }, oldOrg: { name, ABN, email, mobile } }
    let myInit = {
      body: {
        dashboard,
        data,
        op: "edit",
      },
    };

    result = await API.post("partner", "/organisationOp", myInit);
    // console.log(result);
  } catch (e) {
    console.error(e);
  }

  return result;
};
