import {
  OverviewDeckThumbnail,
  PartnerDeckThumbnail,
  WorkbookThumbnail,
  WorksheetsThumbnail,
} from "assets";
import { OverlaySpinner } from "components";

import { ResourceCard } from "./";

export function ResourcesDocuments({ resources }) {
  return (
    <>
      {!resources ? (
        <OverlaySpinner />
      ) : (
        <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
          <ResourceCard
            title="Partner Deck"
            description="The overview of how the partner system work in CyberCert."
            img={PartnerDeckThumbnail}
            classNameImg={"scale-50 laptop:scale-60"}
            onClick={() => {
              !!resources["partner_deck"] &&
                window.open(resources["partner_deck"], "_blank");
            }}
          />
          <ResourceCard
            title="Overview Deck"
            description="The overview of the CyberCert approach in uplifting cyber security in SMBs."
            img={OverviewDeckThumbnail}
            classNameImg={"scale-50 laptop:scale-60"}
            onClick={() => {
              !!resources["overview_deck"] &&
                window.open(resources["overview_deck"], "_blank");
            }}
          />
          <ResourceCard
            title="Workbooks"
            description="The interactive workbooks are PDFs that can be edited, providing certifiers with additional proof of their certificate completion."
            img={WorkbookThumbnail}
            classNameImg={"w-full scale-75"}
            onClick={() => {
              !!resources["workbook_pack"] &&
                window.open(resources["workbook_pack"], "_blank");
            }}
          />
          <ResourceCard
            title="Worksheets"
            description="The worksheet is in Excel format and can be useful for certifiers as additional proof of their certificate completion."
            img={WorksheetsThumbnail}
            classNameImg={"scale-75"}
            onClick={() => {
              !!resources["worksheet_pack"] &&
                window.open(resources["worksheet_pack"], "_blank");
            }}
          />
        </div>
      )}
    </>
  );
}
