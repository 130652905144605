export function FAQDocs() {
  return (
    <div className="flex flex-col gap-4">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray"
        >
          <p className="font-medium text-primary-dark">{`Q${index + 1}: ${faq.question}`}</p>
          <p>{faq.answer}</p>
        </div>
      ))}
    </div>
    // NOTE: You can also use Flowbites Accordion and have them all open by default
  );
}

const faqData = [
  { question: "Sample Question 1", answer: "Answer 1" },
  { question: "Sample Question 2", answer: "Answer 2" },
  // Add more here
];
