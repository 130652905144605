import { FlowbiteButton } from "components/flowbite";
import { HiOfficeBuilding, HiPlus, HiTrendingUp } from "react-icons/hi";

export function PageHeader({
  variant = "default",
  pageTitle,
  username,
  orgName,
  role,
  pageName,
  pageDescription,
  customBreadcrumbs,
  btnLabel,
  customBtn,
  showInsights = null,
  toggleInsights = () => {},
  onClick = () => {},
}) {
  const renderDefault = () => (
    <>
      <div className="text-primary text-2xl font-bold">{pageTitle}</div>
      {/* <Breadcrumbs /> */}
    </>
  );

  const renderTitleOnly = () => (
    <div className="text-primary text-2xl font-bold">{pageTitle}</div>
  );

  const renderPrimeDashboard = () => (
    <>
      <div className="flex flex-col">
        <div className="text-primary text-2xl font-bold">Hi, {username}!</div>
        <div className="flex flex-row items-center gap-2">
          <HiOfficeBuilding className="text-icons" />
          <span className="text-gray text-sm font-medium">
            {orgName} {role && <>/ {role}</>}
          </span>
        </div>
      </div>

      {/* <div className="flex flex-row items-center gap-2">
        <HiClock className="text-icons" />
        <span className="text-gray text-sm font-normal">
          Last login: Yesterday, 11:02 PM, Brisbane, QLD
        </span>
      </div> */}
    </>
  );

  const renderPrimePage = () => (
    <>
      <div className="flex w-full flex-col gap-5 self-stretch">
        <div className="hidden flex-col gap-5 tablet:flex">
          {customBreadcrumbs}
        </div>

        <div className={`flex flex-col laptop:flex-row laptop:items-center laptop:justify-between self-stretch ${btnLabel ? "gap-32" : "gap-0"}`}>
          <div className="flex flex-col gap-1">
            <div className="text-primary text-2xl font-bold">{pageName}</div>
            <div className="text-gray text-sm font-normal">
              {pageDescription}
            </div>
          </div>

          <div className="flex flex-col gap-2 tablet:flex-row">
            {!!btnLabel && (
              <FlowbiteButton
                variant="primary"
                size="small"
                onClick={onClick}
                className="w-full laptop:w-fit"
              >
                <HiPlus className="size-4" />
                {btnLabel}
              </FlowbiteButton>
            )}

            {showInsights !== null && (
              <FlowbiteButton
                variant="surface"
                size="small"
                className="surface-nav text-primary border-primary flex w-full items-center justify-center gap-2 rounded-lg border px-3 py-[7px] outline-none transition-all duration-100 focus:ring-4 focus:ring-gray-100 enabled:hover:bg-gray-100 enabled:hover:shadow-md laptop:w-fit"
                onClick={toggleInsights}
              >
                <HiTrendingUp className="size-4" />
                {showInsights ? "Hide" : "Show"} Insights
              </FlowbiteButton>
            )}

            {!!customBtn && (
              <div className="flex w-full flex-col gap-5">{customBtn}</div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-5">{customBreadcrumbs}</div>

        <div className="flex flex-col">
          <div className="text-primary text-xl font-bold">{pageName}</div>
          <div className="flex flex-row items-center gap-2">
            {/* <HiOfficeBuilding className="text-icons" /> *
            <span className="text-gray text-sm font-medium">
              {pageDescription}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center gap-2 pt-8 laptop:pt-0">
        {/* {customBtn ? (
          <div className="flex w-full flex-col gap-5">{customBtn}</div>
        ) : (
          <Button
            customClasses={`w-full`}
            leftIcon={<HiPlusSm className="size-4" />}
            label={btnLabel}
            labelStyles={`whitespace-nowrap`}
            variant={"blue"}
            onClick={onclick}
          />
        )} *
        <Button
          customClasses={`w-full`}
          leftIcon={<HiOutlineTrendingUp className="size-4" />}
          label={"Hide Insights"}
          labelStyles={`whitespace-nowrap`}
          variant={"white"}
        />
      </div> */}
    </>
  );

  return (
    <div className="flex w-full flex-col justify-between laptop:flex-row">
      {variant === "default" && renderDefault()}
      {variant === "titleOnly" && renderTitleOnly()}
      {variant === "primeDashboard" && renderPrimeDashboard()}
      {variant === "primePage" && renderPrimePage()}
    </div>
  );
}
