const { API } = require("aws-amplify");

export const generateGAPAssessmentResult = async ({
  session,
  assessmentToken,
  resultId,
  answers,
  preview,
  submit,
}) => {
  const myInit = {
    body: { resultId, answers, session, assessmentToken, preview, submit },
  };
  const result = await API.post("GAP", "/assessment/generate", myInit);
  console.log(result);
  return result;
};
