const { API } = require("aws-amplify");

export const fetchGAPAssessmentResult = async ({ assessmentId, id }) => {
  const myInit = {
    queryStringParameters: { assessmentId, id },
  };
  const result = await API.get("partner", "/gap/result/fetch", myInit);
  // console.log(result);
  return result;
};
