export function PartnerAccountManagementDocs({ title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      <video className="w-full border border-gray-300" controls>
        <source src="your-video-file.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
        <p className="font-medium text-primary-dark">Account Management</p>
        <p>
          In settings you can manage your organisation details and add other admin users, assigning them access to the partner dashboard as well.
        </p>
      </div>
    </>
  );
}
