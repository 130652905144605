import AppleLogoPng from "./images/apple-logo.png";
import checkmarkSvg from "./images/checkmark.svg";
import chevronUpDownSvg from "./images/chevron-up-down.svg";
import cybercertIconPng from "./images/cybercert-512x512.png";
import CyberCertBlackSvg from "./images/cybercert-black.svg";
import { ReactComponent as CyberCertLogo } from "./images/cybercert-logo.svg";
import CyberCertWhiteSvg from "./images/cybercert-white.svg";
import sampleChartPng from "./images/dashboard-charts.png";
import SekuroLogoJpeg from "./images/dummy-logos/sekuro-logo.jpeg";
import IconCertificationPng from "./images/icon-certification.png";
import IconCustomersPng from "./images/icon-customers.png";
import IconBronzePng from "./images/icon-level1-bronze.png";
import IconSilverPng from "./images/icon-level2-silver.png";
import IconGoldPng from "./images/icon-level3-gold.png";
import IconPlatinumPng from "./images/icon-level4-platinum.png";
import IconDiamondPng from "./images/icon-level5-diamond.png";
import IconMembershipPng from "./images/icon-membership.png";
import IconReferrerPng from "./images/icon-referrer.png";
import IconSupplyChainPng from "./images/icon-supplychain.png";
import magnifyingGlassSvg from "./images/magnifiying_glass.svg";
import mcgrathIconPng from "./images/mcgrath-32x32.png";
import PlaceholderAvatarPng from "./images/placeholder-avatar.png";
import placeholderLogoPng from "./images/placeholder-logo.png";
import PlayLogoPng from "./images/play-logo.png";
import SampleQRPng from "./images/sample-qr.png";
import sekuroIconPng from "./images/sekuro-icon.png";
import unitingCareIconPng from "./images/unitingcarelogo.png";
import offerOption3Png from "./images/option3.png";
import offerOption2Png from "./images/option2.png";
import offerOption1Png from "./images/option1.png";
import offerDiscount1Png from "./images/discount1.png";
import offerDiscount2Png from "./images/discount2.png";
import offerDiscount3Png from "./images/discount3.png";
import offerPartnerJpg from "./images/partners-offer.jpg";

// Resources
import StylingGuideThumbnailPng from "./images/resources/styling-guide-thumbnail.png";
import PartnerDeckThumbnailPng from "./images/resources/partner-deck-thumbnail.png";
import OverviewDeckThumbnailPng from "./images/resources/overview-deck-thumbnail.png";
import WorkbookThumbnailPng from "./images/resources/workbooks-thumbnail.png";
import WorksheetsThumbnailPng from "./images/resources/worksheets-thumbnail.png";
import BronzeAnimatedWebm from "./images/resources/bronze-animated.webm";
import SilverAnimatedWebm from "./images/resources/silver-animated.webm";
import GoldAnimatedWebm from "./images/resources/gold-animated.webm";
import PlatinumAnimatedWebm from "./images/resources/platinum-animated.webm";
import DiamondAnimatedWebm from "./images/resources/diamond-animated.webm";
import BronzeDefaultPng from "./images/resources/bronze-default.png";
import SilverDefaultPng from "./images/resources/silver-default.png";
import GoldDefaultPng from "./images/resources/gold-default.png";
import PlatinumDefaultPng from "./images/resources/platinum-default.png";
import DiamondDefaultPng from "./images/resources/diamond-default.png";
import BronzeIconPng from "./images/resources/bronze-icon.png";
import SilverIconPng from "./images/resources/silver-icon.png";
import GoldIconPng from "./images/resources/gold-icon.png";
import PlatinumIconPng from "./images/resources/platinum-icon.png";
import DiamondIconPng from "./images/resources/diamond-icon.png";
import BronzeSampleCertificatePng from "./images/resources/bronze-sample.png";
import SilverSampleCertificatePng from "./images/resources/silver-sample.png";
import GoldSampleCertificatePng from "./images/resources/gold-sample.png";
import BronzeSampleAttestationPng from "./images/resources/bronze-attestation-sample.png";
import SilverSampleAttestationPng from "./images/resources/silver-attestation-sample.png";
import GoldSampleAttestationPng from "./images/resources/gold-attestation-sample.png";


export const checkmarkIcon = checkmarkSvg;
export const chevronUpDownIcon = chevronUpDownSvg;
export const cybercertIcon = cybercertIconPng;
export const magnifyingGlassIcon = magnifyingGlassSvg;
export const mcgrathIcon = mcgrathIconPng;
export const unitingCareIcon = unitingCareIconPng;
export const sekuroIcon = sekuroIconPng;
export const sampleChart = sampleChartPng;
export const IconCertification = IconCertificationPng;
export const IconSupplyChain = IconSupplyChainPng;
export const IconMembership = IconMembershipPng;
export const IconCustomers = IconCustomersPng;
export const IconReferrer = IconReferrerPng;
export const IconBronze = IconBronzePng;
export const IconSilver = IconSilverPng;
export const IconGold = IconGoldPng;
export const IconPlatinum = IconPlatinumPng;
export const IconDiamond = IconDiamondPng;
export const PlaceholderAvatar = PlaceholderAvatarPng;
export const CyberCertWhiteLogo = CyberCertWhiteSvg;
export const CyberCertBlackLogo = CyberCertBlackSvg;
export const SampleQR = SampleQRPng;
export const PlayLogo = PlayLogoPng;
export const AppleLogo = AppleLogoPng;
export const placeholderLogo = placeholderLogoPng;
export const SekuroLogo = SekuroLogoJpeg;
export const offerOption3 = offerOption3Png;
export const offerOption2 = offerOption2Png;
export const offerOption1 = offerOption1Png;
export const offerDiscount3 = offerDiscount3Png;
export const offerDiscount2 = offerDiscount2Png;
export const offerDiscount1 = offerDiscount1Png;
export const offerPartnerImg = offerPartnerJpg;

// Resources
export const StylingGuideThumbnail = StylingGuideThumbnailPng;
export const PartnerDeckThumbnail = PartnerDeckThumbnailPng;
export const OverviewDeckThumbnail = OverviewDeckThumbnailPng;
export const WorkbookThumbnail = WorkbookThumbnailPng;
export const WorksheetsThumbnail = WorksheetsThumbnailPng;
export const BronzeAnimated = BronzeAnimatedWebm;
export const SilverAnimated = SilverAnimatedWebm;
export const GoldAnimated = GoldAnimatedWebm;
export const PlatinumAnimated = PlatinumAnimatedWebm;
export const DiamondAnimated = DiamondAnimatedWebm;
export const BronzeDefault = BronzeDefaultPng;
export const SilverDefault = SilverDefaultPng;
export const GoldDefault = GoldDefaultPng;
export const PlatinumDefault = PlatinumDefaultPng;
export const DiamondDefault = DiamondDefaultPng;
export const BronzeIcon = BronzeIconPng;
export const SilverIcon = SilverIconPng;
export const GoldIcon = GoldIconPng;
export const PlatinumIcon = PlatinumIconPng;
export const DiamondIcon = DiamondIconPng;
export const GoldSampleCertificate = GoldSampleCertificatePng;
export const SilverSampleCertificate = SilverSampleCertificatePng;
export const BronzeSampleCertificate = BronzeSampleCertificatePng;
export const GoldSampleAttestation = GoldSampleAttestationPng;
export const SilverSampleAttestation = SilverSampleAttestationPng;
export const BronzeSampleAttestation = BronzeSampleAttestationPng;

export { CyberCertLogo };

export * from "./themes";

