export function PartnerResourcesDocs({ title }) {
    return (
      <>
        <h2 className="text-2xl font-semibold">{title}</h2>
        <video className="w-full border border-gray-300" controls>
          <source src="your-video-file.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="bg-white rounded-lg p-5 border border-gray-200 shadow-lg flex flex-col gap-4 text-gray">
          <p className="font-medium text-primary-dark">Partner Resources</p>
          <p>
            In the resources page you can also find example certificates, example letters of attestation, high resolution copies of the 2D and animated 3D badges, the CyberCert brand kit, and copies of the workbook and worksheet documents.
          </p>
        </div>
      </>
    );
}
  