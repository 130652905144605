import { useEffect, useState } from "react";

import { fetchResourceS3 } from "api";
import { Breadcrumbs } from "components";
import { useDashboard } from "contexts";
import { PageHeader } from "layouts";
import { HiChartPie, HiExternalLink } from "react-icons/hi";
import { RiInformation2Fill } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "variables";

import {
  AttestingToRequirementsDocs,
  CertificationOverviewDocs,
  CompletingCertificationDocs,
  CreateAttestationLetterDocs,
  FAQDocs,
  FullCertificationProcessDocs,
  SignAttestationLetterDocs,
  WorkbookAndWorksheetDocs,
} from "./tabs/certification";
import {
  PartnerAccountManagementDocs,
  PartnerClientManagementDocs,
  PartnerDashboardDocs,
  PartnerGapAssessmentDocs,
  PartnerKnowledgeBaseDocs,
  PartnerPlatformFullWalkthroughDocs,
  PartnerPlatformOverviewDocs,
  PartnerResourcesDocs,
  PartnerSubscriptionsManagementDocs,
} from "./tabs/partner";

// import { CertifyingDocs, PurchasingDocs, UpgradingDocs } from "./tabs";
// import { RegisteringDocs } from "./tabs/certificationOLD/old/registering";
// import { SharingDocs } from "./tabs/certificationOLD/old/sharing";

const TABS = {
  partner: [
    {
      title: "Partner Platform Full Walkthrough",
      slug: "partner-platform-full-walkthrough",
    },
    { title: "Partner Platform Overview", slug: "partner-platform-overview" },
    { title: "Partner Dashboard", slug: "partner-dashboard" },
    { title: "Client Management", slug: "client-management" },
    { title: "GAP Assessment", slug: "gap-assessment" },
    { title: "Subscriptions Management", slug: "subscriptions-management" },
    { title: "Partner Resources", slug: "partner-resources" },
    { title: "Knowledge Base", slug: "knowledge-base" },
    { title: "Account Management", slug: "account-management" },
  ],
  certification: [
    {
      title: "Certification Platform Full Walkthrough",
      slug: "certification-platform-full-walkthrough",
    },
    {
      title: "Certification Platform Overview",
      slug: "certification-platform-overview",
    },
    { title: "Workbook and Worksheet", slug: "workbook-and-worksheet" },
    { title: "Attesting to Requirements", slug: "attesting-to-requirements" },
    {
      title: "Create the attestation letter",
      slug: "create-attestation-letter",
    },
    { title: "Sign the attestation letter", slug: "sign-attestation-letter" },
    {
      title: "Completing your certification",
      slug: "completing-certification",
    },
  ],
};

export function KnowledgeBasePage() {
  const { dashboard } = useDashboard();
  const navigate = useNavigate();
  const { tab } = useParams();

  const [resourceLinks, setResourceLinks] = useState();

  // New state variable for current section
  const [currentSection, setCurrentSection] = useState("partner");
  const [currentTab, setCurrentTab] = useState(() => {
    if (!tab) return 0;
    const currentKey = Object.keys(TABS).find((key) =>
      TABS[key].some((t) => t.slug === tab),
    );

    return currentKey ? TABS[currentKey].findIndex((t) => t.slug === tab) : 0;
  });

  useEffect(() => {
    setup();
  }, []);

  const handleTabChange = (section, index) => {
    setCurrentSection(section);
    setCurrentTab(index);
    navigate(
      `/knowledge-base/${section === "partner" ? `partner/${TABS[section][index].slug}` : `${TABS[section][index].slug}`}`,
      { replace: true },
    );
  };

  const renderCurrentDocs = () => {
    const docsComponents = {
      partner: [
        PartnerPlatformFullWalkthroughDocs,
        PartnerPlatformOverviewDocs,
        PartnerDashboardDocs,
        PartnerClientManagementDocs,
        PartnerGapAssessmentDocs,
        PartnerSubscriptionsManagementDocs,
        PartnerResourcesDocs,
        PartnerKnowledgeBaseDocs,
        PartnerAccountManagementDocs,
      ],
      certification: [
        FullCertificationProcessDocs,
        CertificationOverviewDocs,
        WorkbookAndWorksheetDocs,
        AttestingToRequirementsDocs,
        CreateAttestationLetterDocs,
        SignAttestationLetterDocs,
        CompletingCertificationDocs,
        FAQDocs,
      ],
    };

    // Use currentSection to find the correct component
    const DocsComponent =
      docsComponents[currentSection][
        currentTab % docsComponents[currentSection].length
      ];

    return DocsComponent ? (
      <DocsComponent
        resourceLinks={resourceLinks}
        title={TABS[currentSection][currentTab].title}
      />
    ) : null;
  };

  async function setup() {
    // fetch knowledge center video links
    let result = await fetchResourceS3({
      type: "KNOWLEDGE_CENTER",
    });

    setResourceLinks(result);
  }

  return (
    <>
      <PageHeader
        customBreadcrumbs={
          <Breadcrumbs
            icon={HiChartPie}
            items={{
              "": ROUTES.ROOT,
              "Knowledge Base": ROUTES.KNOWLEDGE_BASE,
              [`${TABS[currentSection][currentTab].title}`]: [
                `${TABS[currentSection][currentTab].slug}`,
              ], // add current selected tab here
            }}
          />
        }
        pageDescription={`The Knowledge Base provides step-by-step guides, FAQs, and expert tips to help you navigate and maximize the CyberCert platform effectively.`}
        pageName="Knowledge Base"
        variant="primePage"
      />

      <section className="flex flex-col gap-8 laptop:flex-row">
        <aside className="laptop:w-75 flex h-fit w-full flex-col gap-8 rounded-lg bg-gray-100 p-5">
          {Object.entries(TABS).map(([section, tabs]) => (
            <ul key={section} className="flex flex-col gap-3">
              <li className="text-primary font-bold">
                {section.toUpperCase()}
              </li>
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  onClick={() => handleTabChange(section, index)}
                  className="cursor-pointer"
                >
                  <span
                    className={
                      currentSection === section && currentTab === index
                        ? "text-brand"
                        : "text-gray hover:text-primary"
                    }
                  >
                    {tab.title}
                  </span>
                </li>
              ))}
            </ul>
          ))}
        </aside>

        <section className="relative flex w-full flex-col gap-8">
          {currentSection !== "partner" && (
            <ResourcesAlert dashboard={dashboard?.orgTitle.toLowerCase()} />
          )}

          {renderCurrentDocs()}
        </section>
      </section>
    </>
  );
}

export function ResourcesAlert({ dashboard }) {
  return (
    <div className="border-primary flex flex-col justify-between gap-4 rounded-lg border bg-gray-100 px-4 py-4 laptop:flex-row laptop:gap-8 laptop:py-2">
      <div className="flex flex-col items-start gap-2 tablet:flex-row tablet:items-center">
        <RiInformation2Fill className="text-icons size-5 flex-shrink-0" />
        <p>
          The certification guides are available for {dashboard} to view from
          the public resources webpage.
        </p>
        {/* <div className="flex flex-col laptop:flex-row gap-1">
          These certification guides are also available for {dashboard} to view
          publicly from our Resources.
          <Link
            className="text-link"
            to={"https://cybercert.ai/en-au/resources"}
            target="_blank"
            rel="noreferrer"
          >
            cybercert.ai/resources.
          </Link>
        </div> */}
      </div>
      <Link
        to={"https://cybercert.ai/en-au/resources"}
        target="_blank"
        rel="noreferrer"
        className="flex h-fit w-fit flex-row items-center gap-1 rounded-xl bg-gray-600 px-3 py-1 text-white hover:bg-gray-700"
      >
        View Resources
        <HiExternalLink className="size-4" />
      </Link>
    </div>
  );
}
